<template>
  <b-container>
    <b-overlay
      :show="showOverlay"
      rounded="sm"
      :variant="variant"
      :opacity="opacity"
    >
      <form id="loginForm" v-on:submit.prevent="submitForm">
        <b-row>
          <b-col md="7">
            <b-alert variant="danger" :show="showError">
              <span class="circle-error">!</span>
              <span>{{ errorMessage }}</span>
            </b-alert>
            <p>Please enter your details to look up your account.</p>
          </b-col>
        </b-row>
        <b-form-row class="form-group">
          <b-col cols="11" md="4">
            <label class="form-label required">Full name</label>
            <div class="validator_wrap" v-if="$v.fullName.$dirty">
              <div class="error" v-if="!$v.fullName.required">
                Field is required
              </div>
            </div>
            <div class="tooltip-wrapper">
              <b-form-input
                class="form-control"
                type="text"
                v-model.trim="$v.fullName.$model"
              />
              <span id="nameInfo" class="info info-right">?</span>
              <b-tooltip
                target="nameInfo"
                placement="right"
                triggers="hover"
                customClass="h-tooltip"
              >
                Enter your full name as first name, other given names and last
                name.
              </b-tooltip>
            </div>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col cols="11" md="4">
            <label class="form-label required">Customer code</label>
            <div class="validator_wrap" v-if="$v.tenantCode.$dirty">
              <div class="error" v-if="!$v.tenantCode.required">
                Field is required
              </div>
            </div>
            <div class="tooltip-wrapper">
              <b-form-input
                class="form-control"
                type="text"
                name="tCode"
                v-model.trim="$v.tenantCode.$model"
              />
              <span id="codeInfo" class="info info-right">?</span>
              <b-tooltip
                target="codeInfo"
                placement="right"
                triggers="hover"
                customClass="h-tooltip"
              >
                Your customer code can be found in your lease pack, on a water
                bill, on a recent payment receipt or you can contact one of our
                friendly customer service officers to provide this to you.
              </b-tooltip>
            </div>
          </b-col>
        </b-form-row>
        <b-form-row class="form-group">
          <b-col cols="11" md="4">
            <label class="form-label required">Year of birth (e.g. 1980)</label>
            <div class="validator_wrap" v-if="$v.yearBirth.$dirty">
              <div class="error" v-if="!$v.yearBirth.required">
                Field is required
              </div>
            </div>
            <b-form-input
              class="form-control"
              type="text"
              v-model.trim="$v.yearBirth.$model"
            />
          </b-col>
        </b-form-row>
        <b-row class="form-group mt-4 mb-4">
          <b-col cols="11" md="4" class="pr-0">
            <button class="btn btn-primary btn-block" type="submit">
              Submit
            </button>
          </b-col>
        </b-row>
      </form>
      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..."></b-spinner>
          <p id="cancel-label">
            We are looking up your account, please wait...
          </p>
        </div>
      </template>
    </b-overlay>
  </b-container>
</template>
<script>
import axios from 'axios';
import { required, numeric } from 'vuelidate/lib/validators';

export default {
  name: 'search',
  data() {
    return {
      errorMessage: null,
      showError: false,
      fullName: '',
      tenantCode: '',
      yearBirth: '',
      showSuccess: false,
      showOverlay: false,
      opacity: 1,
      variant: 'white',
      ipAddress: '',
      userAgent: '',
      userId: '',
      paymentType : ''
    };
  },
  mounted() {
    if(this.$route.params!= null){
      if(this.$route.params.processError){
        this.errorMessage = this.$route.params.processError;
        this.showError = true;
      }
    }
    
    var queryString = new URLSearchParams(window.location.search);
    this.tenantCode = queryString.get('tenantCode');
    this.fullName = queryString.get('fullName');
    this.userId = queryString.get('userId');
    this.paymentType = queryString.get('paymentType');
    this.yearBirth = queryString.get('yearBirth');

    axios.get(process.env.VUE_APP_IP_ADDRESS_API).then((response) => {
      this.response = response.data;
      const receiptObj = JSON.parse(JSON.stringify(this.response));
      this.ipAddress = receiptObj.ip;
    });
    this.userAgent = navigator.userAgent;

    if(this.userId){
      this.submitForm();
    }

  },

  validations: {
    fullName: {
      required,
    },
    tenantCode: {
      required,
    },
    yearBirth: {
      required,
      numeric,
    },
  },

  methods: {
    
    submitForm() {
      this.$v.$touch();
      if (this.fullName && this.tenantCode && this.yearBirth) {
        const userData = {
          fullName: this.fullName,
          tenantCode: this.tenantCode,
          birthYear: this.yearBirth,
          userAgent: this.userAgent,
          ipAddress: this.ipAddress,
          paymentType: this.paymentType,
          userId: this.userId
        };
        this.showOverlay = true;
        axios
          .post(
            `/api/search?code=${process.env.VUE_APP_API_KEY_SEARCH}`,
            userData
          )
          .then(() => {
            this.showError = false;
            this.showOverlay = false;          
            this.$router.push({
              name: 'payments',
              params: {
                tCode: this.tenantCode,
              },
            });
          })
          .catch((error) => {
            this.showOverlay = false;
            if (error.response.status === 404) {
              this.errorMessage =
                'We could not find your customer record matching to the details entered. Please check and try again. If you still have difficulties, please contact us on 1800 004 300 on Monday-Friday between 9am and 5pm.';
            } else if (error.response.status === 400) {
              this.errorMessage =
                'Your query can not processed. Invalid or missing parameters.';
            } else {
              this.errorMessage =
                'Your query can not be processed. Server error.';
            }
            this.showError = true;
          });
      }
    },
  },
};
</script>
